import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';

const News = () => {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/news`)
      .then(response => setNewsItems(response.data))
      .catch(error => console.error('Error fetching news:', error));
  }, []);

  return (
    <div className="news-container">
      <div className="choose2">Новости и акции</div>
	  <motion.div className="news-items"
	  	  	    initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.3 }}>
      {newsItems.length > 0 ? (
        newsItems.map(newsItem => (
          <div key={newsItem.id} className="news-item">
            <p className="news-date">{newsItem.date}</p>
            <p className="news-header">{newsItem.header}</p>
            <p className="news-text">{newsItem.text}</p>
          </div>
        ))
      ) : (
        <p>      Загрузка...</p>
      )}
	   </motion.div>
    </div>
  );
};

export default News;
