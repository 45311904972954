// src/utils/telegram.js
export const Telegram = {
  WebApp: window.Telegram?.WebApp || null,

  init: () => {
    const tg = Telegram.WebApp;
    if (tg) {
      tg.expand(); // Растягиваем интерфейс
    }
    return tg;
  },

  onThemeChanged: (callback) => {
    const tg = Telegram.WebApp;
    if (tg) {
      tg.onEvent('themeChanged', () => {
        callback(tg.colorScheme); // Вызываем callback при смене темы
      });
    }
  },

  openTelegramLink: (link) => {
    const tg = Telegram.WebApp;
    if (tg) {
      tg.openLink(link); // Открываем ссылку в Telegram WebView
    } else {
      window.open(link, '_blank');
    }
  },

  getUserId: () => {
    const tg = Telegram.WebApp;
    return tg?.initDataUnsafe?.user?.id || localStorage.getItem('user_id');
  },

  getColorScheme: () => {
    const tg = Telegram.WebApp;
    return tg?.colorScheme || 'light'; // По умолчанию светлая тема
  },

  close: () => {
    const tg = Telegram.WebApp;
    if (tg) {
      tg.close(); // Закрываем WebApp
    }
  }
};
