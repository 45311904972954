import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/styles/images/marker-icon-2x.png',  // Путь для ретина-иконки
  iconUrl: '/styles/images/marker-icon.png',           // Путь для обычной иконки
  shadowUrl: '/styles/images/marker-shadow.png',        // Путь для тени
});

const EstateMap = ({ estate }) => {
  const customIcon = L.divIcon({
    className: 'custom-marker',
    html: '<div class="marker-content"></div>',
    iconSize: [24, 24],
    popupAnchor: [0, -12]
  });

  // Парсим координаты из поля estate.place
  const [lat, lon] = estate.place.split(',').map(coord => parseFloat(coord.trim()));

  return (
    <MapContainer center={[lat, lon]} zoom={13} style={{ height: 'calc(-48px + 100vh)' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[lat, lon]}>
        <Popup>
		<div style={{ textAlign: 'center', maxWidth: '180px' }}>
          <b>{estate.name}</b><br />
          <img
              src={`${process.env.REACT_APP_API_URL}/${estate.main_photo}`}
              alt={estate.name}
              style={{ width: '100px', height: 'auto', marginTop: '1px', borderRadius: '5px' }}
            />
			</div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default EstateMap;
