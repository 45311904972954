import React, { useEffect, useState } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

const ParallaxBackground = ({ imageUrl }) => {
  const { scrollY } = useViewportScroll();
  const [parallaxEnabled, setParallaxEnabled] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setParallaxEnabled(true);
    }, 100); 
    return () => clearTimeout(timer);
  }, []);

  const y = useTransform(scrollY, [0, 1000], [0, -200]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'fixed',
        top: '-20%', 
        left: '-10%', 
        right: '-10%', 
        bottom: '-20%', 
        zIndex: -1,
        y: parallaxEnabled ? y : 0,
      }}
    />
  );
};

export default ParallaxBackground;
