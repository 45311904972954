import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const fetchCities = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ssindex`);
  return response.data;
};

const SSCityList = () => {
  const navigate = useNavigate();
  const { data: cities = [] } = useQuery('sscities', fetchCities, {
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });

  const handleCityClick = (cityName) => {
    navigate(`/sscity/${cityName}`);
  };

  return (
    <motion.div className="cities-container">
      <div className="choose1">Выберите город</div>
      <motion.div
        className="citylist"
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.25 }}
      >
        {cities.map(city => (
          <div
            key={city.id}
            className="city-action-item"
            onClick={() => handleCityClick(city.name)}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_API_URL}/data/${city.name}.jpg)`
            }}
          >
            <span>{city.name}</span>
          </div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default SSCityList;
