import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { motion, LayoutGroup } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useQuery } from 'react-query';
import '../styles/cityview.css'; 

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/styles/images/marker-icon-2x.png',
  iconUrl: '/styles/images/marker-icon.png',
  shadowUrl: '/styles/images/marker-shadow.png',
});
const fetchCityData = async (cityName) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/city/${cityName}`);
  return response.data;
};
const CityView = ({ onClick }) => {
  const [estates, setEstates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [sortCriteria, setSortCriteria] = useState('default');
  const [showMap, setShowMap] = useState(false);
  const { cityName } = useParams();
  const mapRef = useRef(null);
  const [cityCenter, setCityCenter] = useState([56.497700, 84.974400]);
  const [showInstruction, setShowInstruction] = useState(true);
  const navigate = useNavigate();
  const [expandingImage, setExpandingImage] = useState(null);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const { data: cityData, isLoading } = useQuery(['cityData', cityName], () => fetchCityData(cityName), {
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
  useEffect(() => {
    if (!isLoading && cityData) {
      const cityCoordinates = cityData.center.split(',').map(coord => parseFloat(coord.trim()));
      setCityCenter(cityCoordinates);
      setEstates(cityData.estates);
      setDistricts(cityData.districts);
      setIsContentLoaded(true);
    }
  }, [isLoading, cityData]);
  useEffect(() => {
  if (!isLoading && estates.length > 0) {
    const lastViewedEstateId = sessionStorage.getItem(`lastViewedEstate-${cityName}`);
    if (lastViewedEstateId) {
      setTimeout(() => {
        const estateElement = document.getElementById(`estate-${lastViewedEstateId}`);
        if (estateElement) {
          estateElement.scrollIntoView({ behavior: 'instant', block: 'center' });
          sessionStorage.removeItem(`lastViewedEstate-${cityName}`);
        }
      }, 20); 
    }
  }
}, [isLoading, estates, cityName]);

  const getElementBounds = useCallback((element) => {
    const rect = element.getBoundingClientRect();
    return {
      x: rect.left,
      y: rect.top,
      width: rect.width,
      height: rect.height
    };
  }, []);

  const handleEstateClick = useCallback(async (estateId, imageUrl, initialBounds) => {
    setExpandingImage({ id: estateId, url: imageUrl, initialBounds });
    sessionStorage.setItem(`lastViewedEstate-${cityName}`, estateId);	
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estate/${estateId}`);    
      if (response.data) {
        setTimeout(() => {
          navigate(`/estate/${estateId}`, {
            state: {
              estate: response.data.estate,
              city: response.data.city,
              photos: response.data.photos,
              layouts: response.data.layouts,
            },
          });
        }, 300);
      } else {
        console.error('Ошибка: данные о ЖК не получены');
      }
    } catch (error) {
      console.error('Ошибка загрузки данных для EstateView:', error);
    }
  }, [navigate]);

const EstateItem = React.memo(({ estate, onClick, getElementBounds }) => (
        <motion.div className="estate-wrapper"
		  whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.95 }}
		  id={`estate-${estate.id}`}>
        <div 
          key={estate.id}
          className="estate-action-item" 
          onClick={(e) => handleEstateClick(estate.id, `${process.env.REACT_APP_API_URL}/${estate.main_photo}`, getElementBounds(e.currentTarget))}

          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}/${estate.main_photo})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="estate-info">
            <span className="estate-name">{estate.name}</span>
            <p className="short-description">{estate.shortdesc}</p>
          </div>
        </div>
		</motion.div>
));


  const handleDistrictChange = useCallback((event) => {
    setSelectedDistrict(event.target.value);
  }, []);

  const handleSortChange = useCallback((e) => {
    setSortCriteria(e.target.value);
  }, []);

  const handleMapToggle = useCallback(() => {
    setShowMap(prev => !prev);
  }, []);

  const handleMarkerClick = useCallback(() => {
    setShowInstruction(false);
  }, []);

  const sortedAndFilteredEstates = useMemo(() => {
    let filteredEstates = selectedDistrict
      ? estates.filter(estate => estate.district === selectedDistrict)
      : estates;

    return filteredEstates.sort((a, b) => {
      if (sortCriteria === 'name') return a.name.localeCompare(b.name);
      if (sortCriteria === 'newest') return b.id - a.id;
      return a.id - b.id;
    });
  }, [estates, selectedDistrict, sortCriteria]);



  return (
    <LayoutGroup>
      <div className="city-view">
        <div className="choose2">Выберите ЖК</div>
        <div className="city-controls">
          <button className="city-map-action-item action-item" onClick={handleMapToggle}>
            <p>На карте</p>
            <span className="city-button-item-image">
              <img src={`${process.env.REACT_APP_API_URL}/data/map-icon.svg`} alt="Показать на карте" />
            </span>
          </button>
          <select value={sortCriteria} onChange={handleSortChange}>
            <option value="default">По умолчанию</option>
            <option value="name">По названию</option>
            <option value="newest">Сначала новые</option>
          </select>
          <select id="district-filter" value={selectedDistrict} onChange={handleDistrictChange}>
            <option value="">Все районы</option>
            {districts.map(district => (
              <option key={district} value={district}>{district}</option>
            ))}
          </select>
        </div>
        
        {showMap && (
          <motion.div 
            id="city-map-section" 
            className="section"
initial={{ scale: 0.8, opacity: 0 }}
animate={{ scale: 1, opacity: 1 }}
exit={{ scale: 1.2, opacity: 0 }}
transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <div className="map-container">
              <MapContainer
                center={cityCenter}
                zoom={12}
                style={{ height: 'calc(-48px + 100vh)' }}
                ref={mapRef}
              >
              <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                {estates.map(estate => {
                  const [lat, lon] = estate.place.split(',').map(coord => parseFloat(coord.trim()));
                  return (
                    <Marker key={estate.id} position={[lat, lon]} eventHandlers={{ click: handleMarkerClick }}>
                      <Popup>
                        <div style={{ textAlign: 'center', maxWidth: '180px' }}>
                          <b>{estate.name}</b><br />
                          <Link to={`/estate/${estate.id}`} className="estate-link">Перейти к ЖК</Link><br />
                          <LazyLoadImage
                            src={`${process.env.REACT_APP_API_URL}/${estate.main_photo}`}
                            alt={estate.name}
                            effect="blur"
                            style={{ width: '150px', height: 'auto', marginTop: '1px', borderRadius: '5px' }}
                          />
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            </div>
            {showInstruction && (
              <div id="map-instruction" className="map-instruction">
                Нажмите на маркер, чтобы увидеть название ЖК
              </div>
            )}
            <button id="close-map" className="close-button" onClick={() => setShowMap(false)}>
              <span className="close-svg">
                <img src={`${process.env.REACT_APP_API_URL}/data/close.svg`} alt="Закрыть" />
              </span>
			</button>
          </motion.div>
        )}
        
        <motion.div 
          className="estates-container"
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.25 }}
        >
          {sortedAndFilteredEstates.map((estate) => (
            <EstateItem 
              key={estate.id} 
              estate={estate} 
              onClick={handleEstateClick} 
              getElementBounds={getElementBounds} 
            />
          ))}
        </motion.div>
        <div className="pseudo-bottom"></div>
      </div>
      {expandingImage && (
        <motion.div
          key={`expanding-image-${expandingImage.id}`}
          initial={{
            ...expandingImage.initialBounds,
            scale: 1,
          }}
          animate={{
            x: 0,
            y: 0,
            width: '100vw',
            height: '100vh',
            scale: 1.4,
            transition: { duration: 0.3 }
          }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundImage: `url(${expandingImage.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 4,
            transformOrigin: 'center center',
          }}
        />
      )}
    </LayoutGroup>
  );
};

export default React.memo(CityView);
