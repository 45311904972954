import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/app-bar.css';  // Подключаем стили

const AppBar = () => {
  const [activeButton, setActiveButton] = useState('new-buildings');  // Стейт для активной кнопки

  // Функция для изменения активной кнопки
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="app-bar" id="app-bar">
      <div className="app-bar-nav">
        {/* Кнопка "Новостройки" */}
        <Link 
          to="/" 
          className={`app-bar-item ${activeButton === 'new-buildings' ? 'is-active' : ''}`}
          onClick={() => handleButtonClick('new-buildings')}
        >
          <img className="app-bar-item-image" src={`${process.env.REACT_APP_API_URL}/data/buildings.svg`} alt="Новостройки" />
          <p>Новостройки</p>
        </Link>

        {/* Кнопка "Старт продаж" */}
        <Link 
          to="/ssindex" 
          className={`app-bar-item ${activeButton === 'start-sales' ? 'is-active' : ''}`}
          onClick={() => handleButtonClick('start-sales')}
        >
          <img className="app-bar-item-image" src={`${process.env.REACT_APP_API_URL}/data/fire.svg`} alt="Старт продаж" />
          <p>Старт продаж</p>
        </Link>

        {/* Кнопка "Новости" */}
        <Link 
          to="/news" 
          className={`app-bar-item ${activeButton === 'news' ? 'is-active' : ''}`}
          onClick={() => handleButtonClick('news')}
        >
          <img className="app-bar-item-image" src={`${process.env.REACT_APP_API_URL}/data/info.svg`} alt="Новости" />
          <p>Новости и акции</p>
        </Link>

        {/* Кнопка "Мой кабинет" */}
        <Link 
          to="/account" 
          className={`app-bar-item ${activeButton === 'account' ? 'is-active' : ''}`}
          onClick={() => handleButtonClick('account')}
        >
          <img className="app-bar-item-image" src={`${process.env.REACT_APP_API_URL}/data/fav.svg`} alt="Избранное" />
          <p>Профиль</p>
        </Link>
      </div>
    </div>
  );
};

export default AppBar;
