import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Telegram } from '../utils/telegram';
import '../styles/account.css'; 
import { motion } from 'framer-motion';
import { toast, Toaster } from 'react-hot-toast';

const Account = () => {
  const [favorites, setFavorites] = useState([]);
  const [activeTab, setActiveTab] = useState('coupons'); 
  const [referralCount, setReferralCount] = useState(0);  
  const userId = Telegram.getUserId();
  const [showCouponInstruction, setShowCouponInstruction] = useState(false);  

  const maxReferrals = 25;

  useEffect(() => {
    if (userId) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/favorites?user_id=${userId}`)
        .then(response => setFavorites(response.data))
        .catch(error => console.error('Error fetching favorites:', error));

      axios.get(`${process.env.REACT_APP_API_URL}/api/referral_count?user_id=${userId}`)
        .then(response => setReferralCount(response.data.referral_count || 0))
        .catch(error => console.error('Ошибка получения количества рефералов:', error));
    }
  }, [userId]);

const renderCoupons = () => {
    const couponDescriptions = [
      { name: 'Ипотечный', referrals: 3, description: 'Ипотека+' },
      { name: 'Кэшбек+', referrals: 5, description: 'Кэшбэк +0,05%' },
      { name: 'Кэшбек++', referrals: 10, description: 'Кэшбэк +0,15%' },
	  { name: 'Кэшбек+++', referrals: 25, description: 'Кэшбэк +0,3%' }
    ];

    return couponDescriptions.map((coupon, index) => {
      const isActive = referralCount >= coupon.referrals;
      return (
	    <div className="coupons-box">
        <div key={index} className={`coupon ${isActive ? 'active' : ''}`}>
          <span>{coupon.description}</span>
          <img
            src={`${process.env.REACT_APP_API_URL}/data/${isActive ? 'coup-active.svg' : 'coup.svg'}`}
            alt={`${coupon.name} иконка`}
            className="coupon-icon"
          />
        </div>
		<div className={`coupon-checkbox ${isActive ? 'active-checkbox' : 'inactive-checkbox'}`}>
            {isActive && (
              <img 
                src={`${process.env.REACT_APP_API_URL}/data/check.svg`} 
                alt="Активный купон" 
              />
            )}
        </div>
		</div>
      );
    });
  };
    const handleShowCouponInstruction = () => {
    setShowCouponInstruction(!showCouponInstruction);  
  };

  const handleShare = () => {
    const shareMessage = `Удобный каталог новостроек: https://t.me/mastepakbot?start=ref_${userId}`;
    Telegram.openTelegramLink(`https://t.me/share/url?text=${encodeURIComponent(shareMessage)}`);
  };

  const handleCopyLink = () => {
    const shareMessage = `https://t.me/mastepakbot?start=ref_${userId}`;
    navigator.clipboard.writeText(shareMessage)
      .then(() => {
        toast.success('Ссылка скопирована!', { position: 'top-center' });
      })
      .catch(err => console.error('Ошибка копирования:', err));
  };

  return (
    <div id="account-section" className="section">
      <div className="account-header">

        <div className="account-tabs">
          <button 
            id="tab-coupons" 
            className={`account-tab ${activeTab === 'coupons' ? 'is-active' : ''}`} 
            onClick={() => setActiveTab('coupons')}
          >
            Мои купоны
          </button>
          <button 
            id="tab-favorites" 
            className={`account-tab ${activeTab === 'favorites' ? 'is-active' : ''}`} 
            onClick={() => setActiveTab('favorites')}
          >
            Избранное
          </button>
        </div>
      </div>


      {activeTab === 'coupons' && (
        <motion.div id="coupons-subsection" className="subsection" 
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2 }}>
          <div className="coupons-head">
          <h2>Мои купоны</h2>
		  <motion.button id="how-to-get-coupon" onClick={handleShowCouponInstruction}  whileTap={{ scale: 0.9 }} >Как получить купон?</motion.button>
          </div>
		  <div className="ref-count">Приглашенных пользователей: <span id="referral-count">{referralCount}</span></div>
          <div className="progress-bar">
            <div 
              className="progress-fill" 
              style={{ width: `${Math.min((referralCount / maxReferrals) * 100, 100)}%` }} 
            />
          </div>

          {renderCoupons()}
		  
          <div className="share-buttons">
            <motion.button
              onClick={handleShare}
              className="share-button-acc"
              whileTap={{ scale: 0.9 }}
            >
          <div className="share-button-acc-text">Поделиться</div>
          <span>
            <img src={`${process.env.REACT_APP_API_URL}/data/tg.svg`} alt="TG" />
          </span>
          </motion.button>

            <motion.button
              onClick={handleCopyLink}
              className="copy-link-button"
              whileTap={{ scale: 0.9 }}
            >
			<div className="share-button-copy-text">Коп. ссылку</div>
			 <span>
              <img src={`${process.env.REACT_APP_API_URL}/data/copy.svg`} alt="Копировать ссылку" />
			  </span>
            </motion.button>
          </div>
	        {showCouponInstruction && (
            <motion.div id="coupon-instruction-section" className="section"
			initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.3 }}>
              <div className="coupons-container">
                <div className="instruction-images">
				<p>Поделитесь приложением с помощью кнопки "поделиться" на странице ЖК или в профиле</p>
                <img 
          src={`${process.env.REACT_APP_API_URL}/data/instr-1.png`} 
          alt="Инструкция" 
                  />
                  <p> 3 приглашенных пользователя - купон на сопровождение ипотечного брокера и оформление ипотеки на самых выгодных условиях (до 3,5%)</p>
				  <p> 5 приглашенных пользователей - купон на +0,05% кэшбэка</p>
				  <p> 10 приглашенных пользователей - купон на +0,15% кэшбэка</p>
				  <p> 25 приглашенных пользователей - купон на +0,3% кэшбэка</p>
				<img 
          src={`${process.env.REACT_APP_API_URL}/data/instr-2.png`} 
          alt="Инструкция" 
                  />
				  <p> Купоны на кэшбэк суммируются, максимальная сумма кэшбэка при обращении через приложение составляет 0,5% от стоимости объекта недвижимости</p>
				  <p> В случае покупки приглашенным вами пользователем вы получите кэшбэк в размере 0,1% от стоимости объекта недвижимости</p>
				  <p> Для получения полной информации об объекте недвижимости, применения купона и уточнения условий проведения акции заполните контактную форму на странице ЖК, нажав на кнопку "Узнать подробнее"</p>
                </div>
              </div>
              <button id="close-instruction" className="close-button" onClick={handleShowCouponInstruction}>✖</button>
            </motion.div>
          )}
        </motion.div>
      )}


      {activeTab === 'favorites' && (
        <motion.div id="favorites-subsection" className="subsection" 
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2 }}>
          <h2>Ваши избранные объекты</h2>
          <div id="favorites-list" className="container favorites-container">
            {favorites.length > 0 ? (
              favorites.map(estate => (
                <button 
                  key={estate.id} 
                  className="favorites-button" 
                  onClick={() => window.location.href = `/estate/${estate.id}`}
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URL}/${estate.main_photo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <span className="favestate-name">{estate.name}</span>
                </button>
              ))
            ) : (
              <p>У вас еще нет избранных объектов.</p>
            )}
          </div>
        </motion.div>
      )}
	   <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Account;
