import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import EstateView from './components/EstateView';
import SSEstateView from './components/SSEstateView';
import CityView from './components/CityView';
import SSCityView from './components/SSCityView';
import CityList from './components/CityList';
import SSCityList from './components/SSCityList';
import AppBar from './components/AppBar';
import News from './components/News';
import Account from './components/Account';
import { Telegram } from './utils/telegram';  // Логика работы с API Telegram

function BackButtonManipulator() {
  const tg = Telegram.WebApp;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    function onClick() {
      navigate(-1);
    }
    tg.BackButton.onClick(onClick);

    return () => tg.BackButton.offClick(onClick);
  }, [navigate, tg.BackButton]); // Добавили tg.BackButton в зависимости

  useEffect(() => {
    if (location.pathname === '/') {
      tg.BackButton.isVisible && tg.BackButton.hide();
    } else {
      !tg.BackButton.isVisible && tg.BackButton.show();
    }
  }, [location, tg.BackButton]); // Добавили tg.BackButton в зависимости

  return null;
}


function App() {
  const [userId, setUserId] = useState(null);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const location = useLocation();  // Используем React Router для проверки текущего пути
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.clientHeight;

      if (windowHeight < documentHeight - 100) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const tg = Telegram.WebApp;

    if (tg.initDataUnsafe) {
      tg.expand();
      tg.disableVerticalSwipes();
      tg.setHeaderColor('#800020');
      tg.setBottomBarColor('#800020');

      const initUserId = tg.initDataUnsafe.user?.id;
      if (initUserId) {
        setUserId(initUserId);
        localStorage.setItem('user_id', initUserId);
      } else {
        const storedUserId = localStorage.getItem('user_id');
        if (storedUserId) {
          setUserId(storedUserId);
        }
      }
    } else {
      console.error('Telegram WebApp не найден. Убедитесь, что приложение запускается внутри Telegram.');
    }
  }, []);
    useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const estateId = searchParams.get('estate_id');

    if (estateId) {
      // Перенаправляем на страницу ЖК, если есть параметр estate_id
      navigate(`/estate/${estateId}`);
    }
  }, [navigate]);

  return (
    <div className="App">
      	  {!isKeyboardOpen && <AppBar />}
      <Routes>
        <Route path="/" element={<CityList />} />
        <Route path="city/:cityName" element={<CityView />} />
        <Route path="estate/:estateId" element={<EstateView />} />
        <Route path="/ssindex" element={<SSCityList />} />
        <Route path="/sscity/:cityName" element={<SSCityView />} />
        <Route path="/ssestate/:estateId" element={<SSEstateView />} />
        <Route path="/news" element={<News />} />
        <Route path="/account" element={<Account />} />
      </Routes>

    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
	<BackButtonManipulator/>
      <App />
    </Router>
  );
}
