import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { Telegram } from '../utils/telegram';
import ActionButtons from '../components/ActionButtons';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../styles/custom-gallery.css';
import '../styles/estate.css';
import ParallaxBackground from '../components/ParallaxBackground'; 
import { motion, AnimatePresence } from 'framer-motion';
import { toast, Toaster } from 'react-hot-toast';

const SSEstateView = () => {
  const location = useLocation();
  const estateData = location.state?.estateData;
  const { estateId } = useParams();
  const [estate, setEstate] = useState(location.state?.estate || null);
  const [city, setCity] = useState(location.state?.city || null);
  const [photos, setPhotos] = useState(location.state?.photos || []);
  const [layouts, setLayouts] = useState(location.state?.layouts || []);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteIcon, setFavoriteIcon] = useState(`${process.env.REACT_APP_API_URL}/data/fav.svg`);
  const userId = Telegram.getUserId();
  const [isLoading, setIsLoading] = useState(!estate);
  const [zoomedIndex, setZoomedIndex] = useState(null); 
  const [scale, setScale] = useState(1); // Добавляем стейт для масштаба
  const [referralCount, setReferralCount] = useState(0);  // Количество рефералов
  const [isPriceFieldVisible, setPriceFieldVisible] = useState(false);  // Для отображения поля с купонами
  const [showContactField, setShowContactField] = useState(false);  
  const priceFieldRef = useRef(null);
  const priceButtonRef = useRef(null); 
  const [showCouponInstruction, setShowCouponInstruction] = useState(false);  // Управляем показом инструкции

  useEffect(() => {
    window.scrollTo(0, 0);  // Прокручиваем страницу вверх при монтировании компонента
  }, []);
    useEffect(() => {
    if (zoomedIndex !== null) {
      document.body.style.overflow = 'hidden'; // Блокируем скролл
    } else {
      document.body.style.overflow = ''; // Возвращаем скролл
    }
    return () => {
      document.body.style.overflow = ''; // Удаляем блокировку при размонтировании
    };
  }, [zoomedIndex]);

useEffect(() => {
  if (!estate) {
    axios.get(`${process.env.REACT_APP_API_URL}/api/estate/${estateId}`)
      .then(response => {

        setEstate(response.data.estate);
        setCity(response.data.city);
        setPhotos(response.data.photos || []);
        setLayouts(response.data.layouts || []);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Ошибка загрузки данных:', error);
        setIsLoading(false);
      });
  }

  if (estateId && userId) {
    axios.get(`${process.env.REACT_APP_API_URL}/api/favorites?user_id=${userId}`)
      .then(response => {
        const isFav = response.data.some(fav => fav.id === parseInt(estateId));
        setIsFavorite(isFav);
        setFavoriteIcon(isFav ? `${process.env.REACT_APP_API_URL}/data/fav-active.svg` : `${process.env.REACT_APP_API_URL}/data/fav.svg`);
      })
      .catch(error => console.error('Error checking favorite status:', error));
  }

  if (userId) {
    axios.get(`${process.env.REACT_APP_API_URL}/api/referral_count?user_id=${userId}`)
      .then(response => {
        setReferralCount(response.data.referral_count || 0);
      })
      .catch(error => console.error('Ошибка получения количества рефералов:', error));
  }

}, [estate, estateId, userId]);  // Добавляем массив зависимостей


 const handleTogglePriceField = () => {
    setPriceFieldVisible(!isPriceFieldVisible);
  };

    const handleShowCouponInstruction = () => {
    setShowCouponInstruction(!showCouponInstruction);  // Переключаем видимость инструкции
  };

  useEffect(() => {
        const handleClickOutside = (event) => {
            if (
        priceFieldRef.current &&
        !priceFieldRef.current.contains(event.target) &&
        priceButtonRef.current &&
        !priceButtonRef.current.contains(event.target) // Проверяем, что клик был не по кнопке
      ) {
        setPriceFieldVisible(false); // Закрыть price-field при клике вне
      }
    };
  document.addEventListener("mousedown", handleClickOutside);
  return () => document.removeEventListener("mousedown", handleClickOutside);
}, []);
		

  // Логика отображения купонов
  const renderCoupons = () => {
    const couponDescriptions = [
      { name: 'Ипотечный', referrals: 3, description: 'Ипотека+' },
      { name: 'Кэшбэк+', referrals: 5, description: 'Кэшбэк +0,05%' },
      { name: 'Кэшбэк++', referrals: 10, description: 'Кэшбэк +0,15%' },
	  { name: 'Кэшбэк+++', referrals: 25, description: 'Кэшбэк +0,3%' }
    ];

    return couponDescriptions.map((coupon, index) => {
      const isActive = referralCount >= coupon.referrals;
      return (
	    <div className="coupons-box-est">
        <div key={index} className={`coupon-est ${isActive ? 'active' : ''}`}>
          <span>{coupon.description}</span>
          <img
            src={`${process.env.REACT_APP_API_URL}/data/${isActive ? 'coup-active.svg' : 'coup.svg'}`}
            alt={`${coupon.name} иконка`}
            className="coupon-icon-est"
          />
        </div>
		<div className={`coupon-checkbox-est ${isActive ? 'active-checkbox' : 'inactive-checkbox'}`}>
            {isActive && (
              <img 
                src={`${process.env.REACT_APP_API_URL}/data/check.svg`} 
                alt="Активный купон" 
              />
            )}
        </div>
		</div>
      );
    });
  };
  if (isLoading) {
    return <div className="loading-screen">Загрузка...</div>;
  }

  if (!estate) {
    return <div>Данные о ЖК не найдены.</div>;
  }

  // Комбинируем фото
  const images = [
    ...photos.map(photo => ({
      original: `${process.env.REACT_APP_API_URL}/${photo.photo}`,
      thumbnail: `${process.env.REACT_APP_API_URL}/${photo.photo}`,
    })),
    {
      original: `${process.env.REACT_APP_API_URL}/${estate.main_photo}`,
      thumbnail: `${process.env.REACT_APP_API_URL}/${estate.main_photo}`,
    },
  ];
   const toggleFavorite = () => {
    const url = isFavorite ? `${process.env.REACT_APP_API_URL}/api/favorites/remove` : `${process.env.REACT_APP_API_URL}/api/favorites`;
    axios.post(url, { user_id: userId, estate_id: estateId })
      .then(response => {
        if (response.data.status === 'success') {
          const newFavoriteStatus = !isFavorite;
          setIsFavorite(newFavoriteStatus);
          setFavoriteIcon(newFavoriteStatus ? `${process.env.REACT_APP_API_URL}/data/fav-active.svg` : `${process.env.REACT_APP_API_URL}/data/fav.svg`);
          
          // Показываем уведомление
          if (newFavoriteStatus) {
            toast.success('Добавлено в избранное!', {
              position: 'top-left'
            });
          } else {
            toast('Удалено из избранного', {
              position: 'top-left'
            });
          }
        } else {
          console.error('Error updating favorite status:', response.data.message);
        }
      })
      .catch(error => console.error('Error updating favorite status:', error));
  };

  const handleShare = () => {
    if (!estate || !userId) return;
    const shareMessage = `Смотрите информацию о ЖК ${estate.name}: https://t.me/mastepakbot?start=estate_${estate.id}_${userId}`;
    const path_full = `/share/url?text=${encodeURIComponent(shareMessage)}`;
    Telegram.openTelegramLink(`https://t.me${path_full}`);
  };

  // Обработчик для увеличения изображения
  const handleBackgroundClick = () => {
    setZoomedIndex(null); // Закрываем увеличенное изображение
  };

  // Функция для клика по изображению (не закрывает изображение)
  const handleImageClick = (index) => {
    if (index >= 0 && index < images.length) {
      setZoomedIndex(index);
    }
  };

  const closeZoomedImage = () => {
    setZoomedIndex(null);
  };
  

  return (
    <div className="estate-view" style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden', zIndex: 2 }}>
      <ParallaxBackground imageUrl={`${process.env.REACT_APP_API_URL}/${estate.main_photo}`} />
          <motion.div
           className="estate-content"
           initial={{ scale: 0.98, opacity: 0 }}
           animate={{ scale: 1, opacity: 1 }}
           exit={{ scale: 1.2, opacity: 0 }}
           transition={{ duration: 0.25, ease: "easeInOut" }}
          >
        <div className="estate-info-container">
          <div className="estate-info-left">
            <p className="estate-title">{estate.name}</p>
            <p className="city-title">{city?.name}</p>
            <p className="district-title">{estate.district}</p>
            <motion.button
            className="price-button"
            onClick={handleTogglePriceField}
			ref={priceButtonRef}
            whileTap={{ scale: 0.9 }}
          >
          <div className="price-text">От {estate?.price} млн</div>
          <img src={`${process.env.REACT_APP_API_URL}/data/question.svg`} alt="А как" />
          </motion.button>
		  <AnimatePresence>
            {isPriceFieldVisible && (
              <motion.div
                className="price-field"
				ref={priceFieldRef}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <p className="pf-title">Гарантированный кэшбэк 0.5%</p>
				<p className="pf-bottomtext">При наличии купонов</p>
                {renderCoupons()}
                <motion.button
                  className="buy-button"
                  onClick={handleShowCouponInstruction}
                  whileTap={{ scale: 0.9 }}
                >
                <div className="buy-button-text">Как получить купон?</div>
                <img src={`${process.env.REACT_APP_API_URL}/data/info.svg`} alt="А как" />
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
		   <AnimatePresence>
        </AnimatePresence>
          </div>
          <div className="estate-info-right">
            <motion.div onClick={toggleFavorite} className="favorite-button" whileTap={{ scale: 0.9 }} >
              <span className="fav-item-image">
                <img src={favoriteIcon} alt="Избранное" style={{ width: '35px', height: '35px' }}/>
              </span>
            </motion.div>
            <motion.div onClick={handleShare} className="share-button" whileTap={{ scale: 0.9 }}>
              <span className="share-item-image">
                <img src={`${process.env.REACT_APP_API_URL}/data/share.svg`} alt="Поделиться" />
              </span>
            </motion.div>
          </div>
        </div>
        <p className="description">{estate.description}</p>
        {estate.info && <p className="info">{estate.info}</p>}
        <ActionButtons estate={estate} layouts={layouts} />

 <div className="custom-gallery">
<ImageGallery
  items={images}
  showThumbnails={false}
  showPlayButton={false}
  showFullscreenButton={false}
  showNav={true}
  swipeable={true}
  showBullets={true}
  renderItem={(item) => (
    <div
      className="gallery-item"
      onClick={() => handleImageClick(images.indexOf(item))} // Используем images.indexOf для получения индекса
      style={{ cursor: 'zoom-in' }}
    >
      <img
        src={item.original}
        alt="Фото ЖК"
        className="gallery-image"
        style={{
          width: '100%',
          height: '250px',
          objectFit: 'cover',
        }}
      />
    </div>
  )}
/>

        {/* Увеличенное изображение в новом контейнере */}
        <AnimatePresence>
          {zoomedIndex !== null && images[zoomedIndex] && (
            <motion.div
              className="zoomed-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Затемнённый фон
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleBackgroundClick} // Закрытие при клике на фон
            >
              {/* Увеличенное изображение */}
             <motion.img
                src={images[zoomedIndex].original}
                alt="Фото ЖК"
                initial={{ scale: 1 }}
                animate={{ scale: scale }} // Сохраняем текущий масштаб
                drag
                dragConstraints={{ top: -20, left: -100, right: 100, bottom: 20 }} // Ограничиваем перемещение
                dragElastic={0.3}
                onClick={(e) => e.stopPropagation()} // Отключаем закрытие при клике на изображение
                whilePinch={{ scale: [1, 2, 3] }} // Масштабируем при pinch-to-zoom
                onPinch={(event, info) => setScale(info.scale)} // Обновляем масштаб
                style={{
                  maxWidth: '150%',
                  maxHeight: '150%',
                  objectFit: 'contain', // Сохраняем пропорции изображения
                  cursor: 'grab',
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

	  </motion.div>
	  		  		 		  		   {showCouponInstruction && (
            <motion.div id="coupon-instruction-section" className="section"
						initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.3 }}>
              <div className="coupons-container">
                <div className="instruction-images">
				<p>Поделитесь приложением с помощью кнопки "поделиться" на странице ЖК или в профиле</p>
                          <img 
          src={`${process.env.REACT_APP_API_URL}/data/instr-1.png`} 
          alt="Инструкция" 
                  />
                  <p> 3 приглашенных пользователя - купон на сопровождение ипотечного брокера и оформление ипотеки на самых выгодных условиях (до 3,5%)</p>
				  <p> 5 приглашенных пользователей - купон на +0,05% кэшбэка</p>
				  <p> 10 приглашенных пользователей - купон на +0,15% кэшбэка</p>
				  <p> 25 приглашенных пользователей - купон на +0,3% кэшбэка</p>
				  				                            <img 
          src={`${process.env.REACT_APP_API_URL}/data/instr-2.png`} 
          alt="Инструкция" 
                  />
				  <p> Купоны на кэшбэк суммируются, максимальная сумма кэшбэка при обращении через приложение составляет 0,5% от стоимости объекта недвижимости</p>
				  <p> В случае покупки приглашенным вами пользователем вы получите кэшбэк в размере 0,1% от стоимости объекта недвижимости</p>
				  <p> Для получения полной информации об объекте недвижимости, применения купона и уточнения условий проведения акции заполните контактную форму на странице ЖК, нажав на кнопку "Узнать подробнее"</p>
                </div>
              </div>
              <button id="close-instruction" className="close-button" onClick={handleShowCouponInstruction}>✖</button>
            </motion.div>
          )}
		   <Toaster />
    </div>
  );
};

export default SSEstateView;
