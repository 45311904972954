import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import EstateMap from './EstateMap'; // Импортируем компонент с картой
import { motion, AnimatePresence } from 'framer-motion';
import { toast, Toaster } from 'react-hot-toast'; 

const ActionButtons = ({ estate, layouts }) => {
  const [showLayouts, setShowLayouts] = useState(false);
  const [showMap, setShowMap] = useState(false); // Управляем показом карты
  const [showDetails, setShowDetails] = useState(false);
  const [showMortgageCalc, setShowMortgageCalc] = useState(false);
  const [contactInfo, setContactInfo] = useState(''); // Стейт для контактных данных
  const [message, setMessage] = useState(''); // Сообщение для пользователя после отправки данных
  const handleLayoutsToggle = () => setShowLayouts(!showLayouts);
  const handleMapToggle = () => setShowMap(!showMap); // Переключение карты
  const handleDetailsToggle = () => setShowDetails(!showDetails);
  const handleMortgageCalcToggle = () => setShowMortgageCalc(!showMortgageCalc);
  const handleInputChange = (e) => setContactInfo(e.target.value);
  const [zoomedLayoutIndex, setZoomedLayoutIndex] = useState(null); 
  const [scale, setScale] = useState(1); // Стейт для масштаба планировки
  const detailsInputRef = useRef(null);
  const mortgageInputRef = useRef(null);
  const appBarRef = useRef(null);
  
  useEffect(() => {
    // Получаем app-bar по id или любому другому селектору
    appBarRef.current = document.getElementById('app-bar');

    // Если панель открыта, скрываем app-bar
    if (showDetails || showMortgageCalc) {
      if (appBarRef.current) {
        appBarRef.current.style.display = 'none';
      }
    } else {
      // Если панель закрыта, восстанавливаем отображение app-bar
      if (appBarRef.current) {
        appBarRef.current.style.display = ''; // Или другое исходное значение
      }
    }
  }, [showDetails, showMortgageCalc]);
  
useEffect(() => {
  let timeoutId;
  if (showDetails && detailsInputRef.current) {
    // Задержка для фокуса в 300 мс
    timeoutId = setTimeout(() => {
      detailsInputRef.current.focus();
    }, 300);
  }

  
  if (showMortgageCalc && mortgageInputRef.current) {
    // Задержка для фокуса в 300 мс
    timeoutId = setTimeout(() => {
      mortgageInputRef.current.focus();
    }, 300);
  }

  // Очищаем таймаут при закрытии панели
  return () => {
    clearTimeout(timeoutId);
  };
}, [showDetails, showMortgageCalc]);

  const sendContactData = (messageType) => {
    if (contactInfo.length < 10) {
      // Если длина контакта меньше 10 символов, показываем уведомление
      toast.error('Введите корректные данные'); 
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/api/submit_contact`, {
      contact_info: contactInfo,
      estate_name: estate.name,
      message_type: messageType
    })
    .then(response => {
      if (response.data.status === 'success') {
        toast.success('Ваши данные успешно отправлены');
        setShowDetails(false); // Закрываем bottom-sheet при успешной отправке
        setShowMortgageCalc(false);
      } else {
        toast.error('Ошибка при отправке данных: ' + response.data.message);
      }
    })
    .catch(error => toast.error('Ошибка при отправке данных: ' + error.message));

    setContactInfo('');  // Очищаем поле ввода после отправки
  };

  useEffect(() => {
    if (zoomedLayoutIndex !== null) {
      document.body.style.overflow = 'hidden'; // Блокируем скролл
    } else {
      document.body.style.overflow = ''; // Возвращаем скролл
    }
    return () => {
      document.body.style.overflow = ''; // Удаляем блокировку при размонтировании
    };
  }, [zoomedLayoutIndex]);
    const handleLayoutClick = (index) => {
    setZoomedLayoutIndex(index);
  };

  const closeZoomedLayout = () => {
    setZoomedLayoutIndex(null);
    setScale(1); // Сброс масштаба
  };

  return (
    <div className="action-buttons">
      <div className="details-buttons">
        <motion.div className="layout-action-item action-item" onClick={handleLayoutsToggle} whileTap={{ scale: 0.9 }} >
          <p>Смотреть планировки</p>
          <span className="button-item-image">
            <img src={`${process.env.REACT_APP_API_URL}/data/layouts.svg`} alt="Доступные планировки" />
          </span>
        </motion.div>

        <motion.div className="map-action-item action-item" onClick={handleMapToggle} whileTap={{ scale: 0.9 }} >
          <p>Смотреть на карте</p>
          <span className="button-item-image">
            <img src={`${process.env.REACT_APP_API_URL}/data/map-icon.svg`} alt="Смотреть на карте" />
          </span>
        </motion.div>
      </div>

      <div className="conversion-buttons">
        <motion.div className="conversion-action-item action-item" onClick={handleDetailsToggle} whileTap={{ scale: 0.9 }} >
          <p>Узнать подробнее</p>
          <span className="button-item-image">
            <img src={`${process.env.REACT_APP_API_URL}/data/details.svg`} alt="Узнать подробнее" />
          </span>
        </motion.div>

        <motion.div className="conversion-action-item action-item" onClick={handleMortgageCalcToggle} whileTap={{ scale: 0.9 }} >
          <p>Рассчитать ипотеку</p>
          <span className="button-item-image">
            <img src={`${process.env.REACT_APP_API_URL}/data/mortgage.svg`} alt="Рассчитать ипотеку" />
          </span>
        </motion.div>
      </div>

      {(showDetails || showMortgageCalc) && (
        <div className="bottom-sheet-bg" onClick={() => {
          setShowDetails(false);
          setShowMortgageCalc(false);
        }}></div>
      )}
	  
{showLayouts && layouts && layouts.length > 0 && (
        <motion.div
          id="layouts-section"
		                style={{
                position: 'fixed',

              }}
			initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
      {layouts.map((layout, index) => (
        <div key={index} className="layout" onClick={() => handleLayoutClick(index)}>
          <h5>{layout.description}</h5>
          <img src={`${process.env.REACT_APP_API_URL}/${layout.layout}`} className="layoutpic" alt={layout.description} />
        </div>
      ))}
	        <AnimatePresence>
        {zoomedLayoutIndex !== null && layouts[zoomedLayoutIndex] && (
          <motion.div
            className="zoomed-layout-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={closeZoomedLayout}
          >
            <motion.img
              src={`${process.env.REACT_APP_API_URL}/${layouts[zoomedLayoutIndex].layout}`}
              alt="Планировка"
              initial={{ scale: 1 }}
              animate={{ scale: scale }}
              drag
              dragConstraints={{ top: -300, left: -300, right: 300, bottom: 300 }}
              dragElastic={0.3}
                onClick={(e) => e.stopPropagation()} // Отключаем закрытие при клике на изображение
                whilePinch={{ scale: [1, 2, 3] }} // Масштабируем при pinch-to-zoom
                onPinch={(event, info) => setScale(info.scale)} // Обновляем масштаб
              style={{
                maxWidth: '125%',
                maxHeight: '125%',
                objectFit: 'contain',
                cursor: 'grab',
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
          <button className="close-layouts" onClick={handleLayoutsToggle} >✖</button>
        </motion.div>
      )}

  <AnimatePresence>
        {showDetails && (
          <motion.div
            className="bottom-sheet"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3 }}
            style={{ zIndex: 9999, position: 'fixed' }} 
          >
            <div className="bottom-sheet-inner">
              <div className="bottom-sheet-close" onClick={() => setShowDetails(false)}>✖</div>
              <div className="bottom-sheet-scroll">
                <label htmlFor="details-contact">Введите ваш контакт для связи:</label>
                <input
				  ref={detailsInputRef}
                  type="text"
                  id="details-contact"
                  placeholder="Ваш контакт"
                  value={contactInfo}
                  onChange={handleInputChange}
                />
                 <motion.button
                  id="send-details-contact"
                  className="send-contact"
				  whileTap={{ scale: 0.9 }}
                  onClick={() => sendContactData('жк')}
                >
                  Получить информацию
                </motion.button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* То же самое для ипотеки */}
      <AnimatePresence>
        {showMortgageCalc && (
          <motion.div
            className="bottom-sheet"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3 }}
            style={{ zIndex: 1000, position: 'fixed' }}  
          >
            <div className="bottom-sheet-inner">
              <div className="bottom-sheet-close" onClick={() => setShowMortgageCalc(false)}>✖</div>
              <div className="bottom-sheet-scroll">
                <label htmlFor="mortgage-contact">Введите ваш контакт для связи:</label>
                <input
				  ref={mortgageInputRef}
                  type="text"
                  id="mortgage-contact"
                  placeholder="Ваш контакт"
                  value={contactInfo}
                  onChange={handleInputChange}
                />
                <motion.button
                  id="send-mortgage-contact"
                  className="send-contact"
				  whileTap={{ scale: 0.9 }}
                  onClick={() => sendContactData('ипотека')}
                >
                  Рассчитать ипотеку
                </motion.button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
  
      {/* Отображение карты только если showMap = true */}
      {showMap && (
        <motion.div id="estate-map-section"
initial={{ scale: 0.8, opacity: 0 }}
animate={{ scale: 1, opacity: 1 }}
exit={{ scale: 1.2, opacity: 0 }}
transition={{ duration: 0.25, ease: "easeInOut" }}>
          <EstateMap estate={estate} />
                      <button id="close-map" className="close-button" onClick={() => setShowMap(false)}>
              <span className="close-svg">
                <img src={`${process.env.REACT_APP_API_URL}/data/close.svg`} alt="Закрыть" />
              </span>
			</button>
        </motion.div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default ActionButtons;
